<template>
  <div>
    <div v-if="!videoShow" style="height: 100vh !important">
      <div class="h-100">
        <b-row
          style="height: 20%"
          class="align-items-center justify-content-center"
        >
          <Transition name="slide-down">
            <b-col md="12" lg="12" sm="12" v-if="show" class="text-cente">
              <b-link
                class="brand-logo d-flex justify-content-center align-items-center text-center"
              >
                <img
                  src="@/assets/images/medask.jpeg"
                  width="300"
                  height="200"
                />
              </b-link>
            </b-col>
          </Transition>
        </b-row>
        <b-row
          style="height: 30%"
          class="align-items-center justify-content-center"
        >
          <Transition name="slide-down">
            <div v-if="show" class="text-center w-100">
              <h1 class="text-primary">
                <strong
                  style="
                    font-size: xxx-large;
                    background: -webkit-linear-gradient(#06bd46, #091e3d);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  "
                >
                  <br />
                  Auspicious launching of <br />MedAsk web portal
                </strong>
              </h1>
            </div>
          </Transition>
        </b-row>
        <b-row
          style="height: 50%"
          class="align-items-center justify-content-center"
        >
          <b-col>
            <div>
              <div class="text-center">
                <Transition name="slide-up">
                  <div v-if="show">
                    <b-img
                      src="@/assets/images/icons/rocket2.png"
                      width="210"
                      height="230"
                    >
                    </b-img>
                  </div>
                </Transition>
                <br />
                <br />
                <Transition name="slide-down">
                  <div v-if="show">
                    <b-button
                      style="width: 223px; height: 65px"
                      variant="primary"
                      @click="New"
                      :disabled="showBTn"
                      size="lg"
                      pill
                    >
                      <span class="align-middle"> Go Live </span>
                    </b-button>
                  </div>
                </Transition>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BProgress, BAlert, BLink, BAvatar } from "bootstrap-vue";
import doctors from "@/assets/images/doctors.gif";
import sthscope from "@/assets/images/sthscope.png";
// import urdu from "@/assets/urdu.json";
// import eng from "@/assets/eng.json";

import { mapGetters, mapActions } from "vuex";
import { $themeConfig } from "@themeConfig";
import Firework from "./fireworks.vue";
export default {
  components: {
    BProgress,
    BAlert,
    BAvatar,
    BLink,
    Firework,
  },
  data() {
    return {
      bars: [{ variant: "primary", value: 0 }],
      appLogoImage: "",
      show: true,
      doctors,
      sthscope,
      isPlaying: false,
      showBTn: false,
      timer: null,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    ...mapActions({
      setUserInaguration: "appData/setUserInaguration",
    }),
    New() {
      this.showBTn = true;
      this.show = !this.show;
      this.timerFunction();
      this.play();
    },
    routeLanding() {
      this.setUserInaguration("fireinthehole");
      window.location.href = "https://dev.medask.com.pk/";
    },
    openVideo() {
      this.videoShow = true;
    },
    timerFunction() {
      setTimeout(this.routeLanding, 2000);
      //setTimeout(this.routeLanding, 4000);
    },
    fadeMe: function () {
      this.show = !this.show;
    },
    play() {
      this.$refs.myvideo.play();
      this.isPlaying = true;
    },
    stop() {
      this.$refs.myvideo.pause();
      this.isPlaying = false;
    },
    Website() {
      this.timer = setInterval(() => {
        // eslint-disable-next-line
        this.bars.forEach((bar) => (bar.value += 1));
        if (this.bars[0].value >= 100) {
          clearInterval(this.timer);
          this.timer = null;
          //this.$router.push({ path: /landing }).catch(err => { })
        }
      }, 100);
    },
  },
  computed: {
    ...mapGetters({
      language: "appData/language",
      inaguration: "appData/inaguration",
    }),
  },
};
</script>
<style scoped>
video {
  height: 99.6vh;
  width: 100vw;
  object-fit: fill;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.slide-right-enter-active {
  transition: all 0.3s ease-out;
}

.slide-right-leave-active {
  transition: all 3s ease-out;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translate(1000px, 0);
}

.slide-left-enter-active {
  transition: all 0.3s ease-out;
}

.slide-left-leave-active {
  transition: all 3s ease-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translate(-1000px, 0);
}

.slide-up-enter-active {
  transition: all 0.3s ease-out;
}

.slide-up-leave-active {
  transition: all 3s ease-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translate(0, -1000px);
}
.slide-video-enter-active {
  transition: all 0.5s ease-out;
}

.slide-video-leave-active {
  transition: all 5s ease-out;
}

.slide-video-enter-from,
.slide-video-leave-to {
  transform: translate(0, -1000px);
}

.slide-down-enter-active {
  transition: all 0.3s ease-out;
}

.slide-down-leave-active {
  transition: all 3s ease-out;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translate(0, 1000px);
}
</style>
